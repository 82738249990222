import './App.css';
import { ScrollProvider } from './components/ScrollContext';
import Router from './routes/router';

function App() {
  return (
    <>
      <ScrollProvider>
        <Router></Router>
      </ScrollProvider>
    </>
  );
}

export default App;
