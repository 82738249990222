import TejaAla30 from '../assets/Products/Teja-Ala-30.png';
import TejaAla36 from '../assets/Products/Teja-Ala-36.png';
import TejaAla45 from '../assets/Products/Teja-Ala-45.png';
import TejaArabe from '../assets/Products/Teja-Árabe.png';
import TejaMediaCana from '../assets/Products/Teja-media-cana.png';
import L3030 from '../assets/Products/Loseta-L3030.png';
import FachaletaNegra from '../assets/Products/Fachaleta-negra.png';
import FachaletaNatural from '../assets/Products/Fachaleta-natural.png';
import C18 from '../assets/Products/Celosia-C18.png';
import C17 from '../assets/Products/Celosia-C17.png';
import C13 from '../assets/Products/Celosia-C13.png';
import C5 from '../assets/Products/Celosia-C5.png';
import C2 from '../assets/Products/Celosia-C2.png';
import C1 from '../assets/Products/Celosia-C1.png';
import C10 from '../assets/Products/Celosia-C10.png';

const ProductData = [
  {
    name: 'Teja ala 30',
    width: '16.6 cm',
    length: '30 cm',
    thickness: '1.2 cm',
    pieces: 26,
    weight: '1.100 kg',
    image: TejaAla30,
    url: 'teja-ala-30',
    backgroundColor: '#F2F2F2',
    category: 'Tejas',
  },
  {
    name: 'Teja ala 36',
    width: '19 cm',
    length: '36 cm',
    thickness: '1.2 cm',
    pieces: 18,
    weight: '1.400 kg',
    image: TejaAla36,
    url: 'teja-ala-36',
    backgroundColor: '#F2F2F2',
    category: 'Tejas',
  },
  {
    name: 'Teja ala 45',
    width: '28 cm',
    length: '45 cm',
    thickness: '1.2 cm',
    pieces: 10,
    weight: '3.000 kg',
    image: TejaAla45,
    url: 'teja-ala-45',
    backgroundColor: '#FFE0C4',
    category: 'Tejas',
  },
  {
    name: 'Teja Árabe',
    width: '13.5 y 16.5 cm',
    length: '40 cm',
    thickness: '1.2 cm',
    pieces: 27,
    weight: '1.200 kg',
    image: TejaArabe,
    url: 'teja-arabe',
    backgroundColor: '#F0EBE7',
    category: 'Tejas',
  },
  {
    name: 'Teja Media',
    width: '16.5 cm',
    length: '30 cm',
    thickness: '1.2 cm',
    pieces: 32,
    weight: '1.150 kg',
    image: TejaMediaCana,
    url: 'teja-media',
    backgroundColor: '#FFE0C4',
    category: 'Tejas',
  },
  {
    name: 'L3030',
    width: '30 cm',
    length: '30 cm',
    thickness: '1.2 cm',
    pieces: 10,
    weight: '2.200 kg',
    image: L3030,
    url: 'L3030',
    backgroundColor: '#F0EBE7',
    category: 'Losetas',
  },
  {
    name: 'Fachaleta Negra',
    width: '7.5 cm',
    length: '25 cm',
    thickness: '1.2 cm',
    pieces: 48,
    weight: '0.350 kg',
    image: FachaletaNegra,
    url: 'fachaleta-negra',
    backgroundColor: '#F2F2F2',
    category: 'Fachaletas',
  },
  {
    name: 'Fachaleta Natural',
    width: '7.5 cm',
    length: '25 cm',
    thickness: '1.2 cm',
    pieces: 48,
    weight: '0.350 kg',
    image: FachaletaNatural,
    url: 'fachaleta-natural',
    backgroundColor: '#F0EBE7',
    category: 'Fachaletas',
  },
  {
    name: 'C18',
    width: '8 cm',
    length: '19 cm',
    height: '19 cm',
    pieces: 25,
    weight: '2.200 kg',
    image: C18,
    url: 'c18',
    backgroundColor: '#F2F2F2',
    category: 'Celosias',
  },
  {
    name: 'C17',
    width: '8 cm',
    length: '19 cm',
    height: '19 cm',
    pieces: 25,
    weight: '2.000 kg',
    image: C17,
    url: 'c17',
    backgroundColor: '#F2F2F2',
    category: 'Celosias',
  },
  {
    name: 'C13',
    width: '8 cm',
    length: '32 cm',
    height: '16 cm',
    pieces: 18,
    weight: '2.900 kg',
    image: C13,
    url: 'c13',
    backgroundColor: '#F2E8DD',
    category: 'Celosias',
  },
  {
    name: 'C5',
    width: '6 cm',
    height: '13 cm',
    length: '24 cm',
    pieces: 30,
    weight: '1.600 kg',
    image: C5,
    url: 'c5',
    backgroundColor: '#F0DCCA',
    category: 'Celosias',
  },
  {
    name: 'C2',
    width: '6 cm',
    height: '12 cm',
    length: '24 cm',
    pieces: 32,
    weight: '1.430 kg',
    image: C2,
    url: 'c2',
    backgroundColor: '#F2E8DD',
    category: 'Celosias',
  },
  {
    name: 'C1',
    width: '6 cm',
    length: '24 cm',
    height: '13 cm',
    pieces: 30,
    weight: '1.700 kg',
    image: C1,
    url: 'c1',
    backgroundColor: '#FFE0C4',
    category: 'Celosias',
  },
  {
    name: 'C10',
    width: '8 cm',
    length: '19 cm',
    height: '19 cm',
    pieces: 25,
    weight: '2.500 kg',
    image: C10,
    url: 'c10',
    backgroundColor: '#F2F2F2',
    category: 'Celosias',
  },
];

export default ProductData;
