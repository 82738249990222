import { styled } from '@mui/material';
import theme from '../../styles';
import { NavLink } from 'react-router-dom';

const ProductCard = (props) => {
  const MainContainer = styled('div')(({ backgroundColor }) => ({
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Ensure content is spaced out
    color: theme.color.light,
    backgroundColor: backgroundColor || '#F0DCCA',
    fontSize: '20px',
    fontWeight: '900',
    fontFamily: theme.fontFamily.primary,
    transition: 'transform 0.3s ease-out',
    flex: '1',
    gap: 5,
    boxSizing: 'border-box',
    height: '100%', // Ensure the card stretches to fill the available height
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  }));

  const Image = styled('img')(() => ({
    width: '100%',
  }));

  const H1 = styled('h1')(() => ({
    color: 'black',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '700',
    fontSize: 25,
    margin: 0,
    textDecoration: 'none',
  }));

  const Paragraph = styled('p')(() => ({
    color: 'black',
    fontFamily: theme.fontFamily.primary,
    fontWeight: '400',
    fontSize: 14,
    margin: 0,
    textDecoration: 'none',
  }));

  const CustomNavLink = styled(NavLink)(() => ({
    textDecoration: 'none',
    color: 'inherit', // Ensure the color is inherited
  }));

  return (
    <CustomNavLink to={`/product/${props.item.url}`}>
      <MainContainer backgroundColor={props.item.backgroundColor}>
        <Image src={props.item.image} alt={props.item.name} />
        <H1>{props.item.name}</H1>
        <Paragraph>Más información</Paragraph>
      </MainContainer>
    </CustomNavLink>
  );
};

export default ProductCard;
