import HeroSection from '../../sections/HeroSection';
import HighlightedProducts from '../../sections/HighlightedProducts';
import Navbar from '../../components/Navbar'
import ContactSection from '../../sections/Contact'

function Home() {
  return (
    <>
      <Navbar position='fixed'/>
      <HeroSection />
      <HighlightedProducts />
      <ContactSection/>
    </>
  );
}

export default Home;
