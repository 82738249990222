import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import NoMatch from '../pages/NoMatch';
import Product from '../pages/Product';
import Products from '../pages/Products';
import Footer from '../sections/Footer'

const Router = (props) => {
  return (
    <>
    <Routes>
      <Route index element={<Home />} />
      <Route path='home' element={<Home />} />
      <Route path='/product/:url' element={<Product />} />
      <Route path='/products' element={<Products/>} />
      <Route path='*' element={<NoMatch />} />
    </Routes>
    <Footer/>
    </>
  );
};

export default Router;
